
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import BlogCard from '../../../components/Blogs/BlogCard'
import BlogSidebar from '../../../components/Sidebars/BlogSidebar'
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const Blogs = () => {
 useDocumentTitle('Blogs | Luday AB');
  useScrollTop();
    return (
        <>
        < Banner  titleText='Our Blogs' subtitleText='Blogs'/>

        <section className="bg-white">
            <div className="max-w-7xl px-6 py-5 mx-auto">
                <div className='flex flex-col md:flex-row mt-5'>
                    <div className='w-auto md:w-3/5 m-5 md:m-10'>

                        < BlogCard />
                    
                    </div>
                    <div className='w-auto md:w-2/5 m-5 md:m-10'>

                        < BlogSidebar />

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Blogs;
 