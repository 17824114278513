
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import BlogDetailContent from './BlogDetailContent'
import BlogDetailSidebar from '../../../components/Sidebars/BlogDetailSidebar'
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const BlogDetails = () => {
  useDocumentTitle('BlogDetails | Luday AB');
  useScrollTop();
  return (
	<div className="app min-h-screen">
    < Banner  titleText='Our Blogs' subtitleText='Blogs'/>
    < BlogDetailContent />
	</div>
  )
}
export default BlogDetails;
