
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import { ArrowRightIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';

const BlogCard = () => {

  const [blogs, fetchBlogs] = useState([]);
  //   const [loading, setLoading] = useState(false);
  const [sliceEnd, setSliceEnd] = useState(10);
  const getData = () => {
    // setLoading(true)
      fetch(`${ROUTE.BLOGS_API}/blogs`)
        .then((res) => res.json())
        .then((res) => {
          fetchBlogs(res.results)
          //   setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const paginatedBlogs = blogs.slice(0, sliceEnd);

    const loadMore = () => {
        setSliceEnd(sliceEnd + 10)
    };

  return (
   
    <>
        <div className="pb-10">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Stay Updated with Happenings in the Tech Space</p>
        </div>       
		{paginatedBlogs.map((blog) => (
		<div key={blog.id} className="p-1 items-center justify-center w-full rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-xl hover:rounded-2xl mb-5">
            <img  className="mx-auto w-full block md:w-4/12 h-52 rounded-lg"  alt={blog.title} loading="lazy" src={`${CONSTANT.IMAGE_STORE}/${blog.image_path}`} />
            <div className="sm:w-8/12 pl-0 p-5">
                <div className="space-y-2">
                    <div className="space-y-4">
                        <h2 className="text-md font-semibold text-cyan-900">
                        {blog.title}
                        </h2>
                    </div>
                    <div className="flex items-center space-x-4 text-justify">
                        <p className="text-sm max-h-20 overflow-hidden text-ellipsis">{blog.summary}</p>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex flex-row space-x-1">
                            <Link to={`${ROUTE.BLOG_DETAILS}/${blog.slug}`}
                            className=" text-gray-800 cursor-pointer px-3 text-center justify-center items-center py-1 rounded-xl flex space-x-2 flex-row">
                            Continue Reading
                            <ArrowRightIcon className='h-5'/>
                            </Link>
                        </div>
                        <div className="text-grey-500 flex flex-row space-x-1  my-4">
                            <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <p className="text-xs">{blog.created_at}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		))}
        {blogs.length > 10 ?
        <div className='ml-10 mt-20'>
            <button onClick={loadMore} className='flex flex-row items-center border-2 border-blue-400 rounded-md p-2'>Load more <PlusCircleIcon className='h-5 text-blue-400'/></button>
        </div>
        :
        ""
        }
    </>
    )
}

export default BlogCard
 