// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    scroll-behavior: smooth;
  }

.nturl {
  box-shadow: none !important;
}

[data-gt-lang="en"] {
  /* background-color: red !important; */
  box-shadow: none !important;
  top: 2.5rem !important;
}

[data-gt-lang="fr"] {
  /* background-color: red !important; */
  box-shadow: none !important;
  left: 0.5295px !important;
  top: 2.5rem !important;
}

[data-gt-lang="sv"] {
  /* background-color: yellow !important; */
  box-shadow: none !important;
  left: 41.5295px !important;
  top: 2.5rem !important;
}

.gsatelite {
  box-shadow: none !important;
  text-shadow: none !important;
}

.gt-current-lang {
  box-shadow: none !important;
  text-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;EACzB;;AAEF;EACE,2BAA2B;AAC7B;;AAEA;EACE,sCAAsC;EACtC,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,2BAA2B;EAC3B,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,yCAAyC;EACzC,2BAA2B;EAC3B,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":["html {\n    scroll-behavior: smooth;\n  }\n\n.nturl {\n  box-shadow: none !important;\n}\n\n[data-gt-lang=\"en\"] {\n  /* background-color: red !important; */\n  box-shadow: none !important;\n  top: 2.5rem !important;\n}\n\n[data-gt-lang=\"fr\"] {\n  /* background-color: red !important; */\n  box-shadow: none !important;\n  left: 0.5295px !important;\n  top: 2.5rem !important;\n}\n\n[data-gt-lang=\"sv\"] {\n  /* background-color: yellow !important; */\n  box-shadow: none !important;\n  left: 41.5295px !important;\n  top: 2.5rem !important;\n}\n\n.gsatelite {\n  box-shadow: none !important;\n  text-shadow: none !important;\n}\n\n.gt-current-lang {\n  box-shadow: none !important;\n  text-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
