
export { default as Home } from './default/Home';
export { default as About } from './default/about/About';
export { default as Internship } from './default/about/Internship';
export { default as Blog } from './default/blogs/Blogs';
export { default as BlogDetails } from './default/blogs/BlogDetails';
export { default as Contact } from './default/Contact';
export { default as Quote } from './default/quote/Quote';
export { default as Portfolio } from './default/portfolio/Portfolio';
export { default as Services } from './default/services/Services';
export { default as ServiceDetails } from './default/services/ServiceDetails';
export { default as NotFound } from './default/NotFound';
 export {default as AffiliatePage} from './default/affiliate/Affiliate'
 export {default as EbiorePolicy} from './default/policy/app/Ebiore'
 