
import React, {useEffect, useState} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowRightIcon,EnvelopeIcon } from '@heroicons/react/20/solid'
import * as ROUTE from '../../constants/routes';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import * as CONSTANT from '../../constants/constants';

const BlogDetailsSidebar = () => {
	
	const {register, handleSubmit, reset, formState: { errors } } = useForm();
	const {slug} = useParams();
	const navigate = useNavigate();
	const [filteredBlogs, setFilteredBlog] = useState([]);
	// const [loading, setLoading] = useState(false);
	const getData = () => {
		// setLoading(true)
		fetch(`${ROUTE.BLOGS_API}/blogs`)
			.then((res) => res.json())
			.then((res) => {
			const filter = res.results.filter((blog) => blog.slug !== slug).slice(0, 5);
			setFilteredBlog(filter)
			// setLoading(false)
			})
		};

		useEffect(() => {
			getData()
		}, [])
  
	const successAlert = (response) => {
			return(
			swal({
				title: "Info!",
				text: response,
				icon: "success"
			})              
			)
		}
	
		const submitForm = (data) => {
			const body = {
			email: data.email
			}
	
			const requestOptions = {
				method: "POST",
				headers: {
				'content-type': 'application/json'
				},
				body: JSON.stringify(body)
			}
	
			fetch(`${ROUTE.CONTACT_API}/newsletter/add, requestOptions`)
			.then(res => res.json())
			.then(data =>{
				console.log(data)
				successAlert(data.message)
			})
			.catch(err => console.log(err))
	
			reset()
		}


	return (
		<>
			<div className="">
				<h3 className="text-xl font-semibold">Other storys</h3>
				<hr></hr>
   			</div>
			{filteredBlogs.map((blog) => (
			<div key={blog.id} className="flex flex-row p-1 items-center justify-left w-full rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-md mt-5 mb-10">
				<img  className="w-20 block h-20 rounded-lg"  alt={blog.title} loading="lazy" src={`${CONSTANT.IMAGE_STORE}/${blog.image_path}`} />
				<div className="sm:w-8/12 pl-0 p-2">
					<div className="space-y-2">
						<div className="space-y-4">
							<h2 className="text-md font-semibold text-cyan-900">
							{blog.title}
							</h2>
						</div>
						<div className="flex justify-between">
							<div className="flex flex-row space-x-1">
								<Link to={`${ROUTE.BLOG_DETAILS}/${blog.slug}`}
								className=" text-gray-800 cursor-pointer px-3 text-center justify-center items-center py-1 rounded-xl flex space-x-2 flex-row"
								onClick={() => navigate.push(`${ROUTE.BLOG_DETAILS}/${blog.slug}`)}
  								>
								Continue Reading
								<ArrowRightIcon className='h-5'/>
								</Link>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			))}
			<div className='flex flex-col h-[800px] from-gray-100 via-gray-100 to-gray-100 bg-gradient-to-br'>
				<div className="m-10 items-center justify-center w-auto h-96 rounded-xl group space-x-6 bg-white bg-opacity-50 shadow-sm hover:rounded-2xl">
					<h5 className='mt-9 p-10 text-3xl font-bold tracking-tight text-gray-900 flex justify-center'>Let the post come to you</h5>
					<form>
						<div className="pr-5 mr-5">
						<div className="space-y-2">
							<div className="space-y-4">
								<div className="relative z-0">
									<input type="text" name="email" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " {...register("email", { required: true, maxLength: 50 })} />
									<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Your email</label>
								</div>
								 {errors.email && <small className="text-red-500 text-xs italic">Your e-mail is required</small>}
                                    {errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 100 </small></p>}
							</div>
							<div className="flex items-center space-x-4 justify-center">
								<div className="flex flex-row space-x-1">
									<button
										className="bg-white shadow-sm text-xl mt-4 text-gray-500 cursor-pointer px-3 py-1 text-center justify-center items-center rounded-md flex space-x-2 flex-row border-2 border-blue-400 " onClick={handleSubmit(submitForm)}>
										Subscibe
										<EnvelopeIcon className='h-5 ml-2'/>
									</button>
								</div>
							</div>
						</div>
					</div>
  					</form>
				</div>
				<div className="m-10 ">
					<h5 className=' py-10 text-3xl font-bold tracking-tight text-gray-900 flex justify-center'>We are social</h5>
					<div className="mt-1 ">
						<div className="space-y-2  pr-5">
							<div className="space-y-4">
								<div className="mx-auto text-center mt-2">
									<ul className="flex justify-center mb-4 md:mb-0">
										<li>
											<a href='https://www.linkedin.com/company/luday/' target='_blank' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="Linkedin">
												<svg className="w-8 h-8 fill-current" viewBox="0 -8 17 30" xmlns="http://www.w3.org/2000/svg">
													<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
												</svg>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</>
	)
};

export default BlogDetailsSidebar;
 